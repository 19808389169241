import { RouterProvider } from "react-router-dom";
import {
  BudgeUIProvider,
  ErrorBoundary,
  errorManager,
  isInvalidTokenError,
  PageLoader,
  ThunkErrorPayload,
} from "@budgeinc/budge-ui-core";
import { errorLogger } from "api/BudgeApi";
import { setupI18n } from "i18n";
import { SafeAreaProvider } from "react-native-safe-area-context";
import routerConfig from "common/routerConfig";
import { PropsWithChildren, Suspense, useMemo } from "react";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ErrorResponse } from "@budgeinc/budge-api";
import { TBaseMutationVariables } from "api/hooks/types";
import { useAppMessages } from "store/global";
import getStoreConfig from "store";
import { Provider as ReduxProvider } from "react-redux";

setupI18n();

const { store } = getStoreConfig();

const queryCache = new QueryCache();

const QueryClientSetup = ({ children }: PropsWithChildren) => {
  const messages = useAppMessages();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        queryCache,
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: false,
            gcTime: 5 * 60 * 1000, // 15 mins
            staleTime: 30 * 60 * 1000, // 30 mins
          },
          mutations: {
            retry: 0,
            onError: (e, variables) => {
              const vars = variables as TBaseMutationVariables;

              // When migration is completed, set this to === false
              // so that by default the error modal will show up
              if (!vars?.showErrorModal) {
                // dont show error modal if explicitly set to false
                return;
              }

              let error: AxiosError<ErrorResponse> = e as any;
              const errorResponse = error.response?.data;
              const thunkError = e as ThunkErrorPayload;

              if (thunkError.apiError != null) {
                error = thunkError.apiError;
              }

              if (errorResponse && isInvalidTokenError(errorResponse)) {
                // dont show error modal in that case
                return;
              }

              errorManager.showError({
                key: errorResponse?.message,
                error: error?.response?.data,
              });
            },
          },
        },
      }),
    [messages]
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

const App = () => (
  <ReduxProvider store={store}>
    <QueryClientSetup>
      <SafeAreaProvider style={{ flex: 1, height: "100%" }}>
        <BudgeUIProvider>
          <ErrorBoundary
            onReset={() => window.location.reload()}
            onError={error => {
              if (error) {
                errorLogger.logClientError(error);
              }
            }}
          >
            <Suspense fallback={<PageLoader />}>
              <RouterProvider router={routerConfig} fallbackElement={<PageLoader />} />
            </Suspense>
          </ErrorBoundary>
        </BudgeUIProvider>
      </SafeAreaProvider>
    </QueryClientSetup>
  </ReduxProvider>
);

export default App;
