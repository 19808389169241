import {
  BackOfficeApi,
  ClientErrorInputTargetEnum,
  Configuration,
  EmployeesApi,
  EmployersApi,
  IntakeApi,
  UsersApi,
  DiscoveryApi,
  AuthZApi,
  MethodApi,
  PlaidApi,
  EmailApi,
  ErrorResponse,
  DebtPaymentPlansApi,
  AccountsApi,
  AccountsCrossTenantApi,
  DebtPaymentPlansCrossTenantApi,
  ProgramsApi,
  ProgramsCrossTenantApi,
  SubscriptionsApi,
  SubscriptionsCrossTenantApi,
  EmployerRepresentativesCrossTenantApi,
  InvitesApi,
  InvitesCrossTenantApi,
  CreditScoresApi,
  CreditScoresCrossTenantApi,
  DirectPaymentsApi,
  DirectPaymentsCrossTenantApi,
  EmployeesCrossTenantApi,
  CampaignsCrossTenantApi,
  SentimentsApi,
  SentimentsCrossTenantApi,
  LoginsCrossTenantApi,
  ReferralsApi,
  ManagersCrossTenantApi,
  OnePaymentPlansApi,
  OnePaymentPlansCrossTenantApi,
  ApiKeysApi,
} from "@budgeinc/budge-api";
import { ErrorLogger, errorManager, isInvalidTokenError, isJWTExpired } from "@budgeinc/budge-ui-core";
import axios, { AxiosError } from "axios";
import { refreshAccessToken } from "store/user/thunks";
import { appStore } from "store";
import { appVersion } from "utils/env";
import { userActions } from "store/user/slice";
import { AdminAppStorage } from "utils/storage";

const apiUrl = process.env.REACT_APP_API_URL;

export const budgeApiConfig = new Configuration({
  basePath: apiUrl,
  accessToken: AdminAppStorage.getAccessToken() ?? undefined,
});

export const budgeAxiosClient = axios.create({
  baseURL: budgeApiConfig.basePath,
  headers: {
    "X-Budge-App-Version": appVersion || "unknown",
  },
});

export const apiKeyApi = new ApiKeysApi(budgeApiConfig, undefined, budgeAxiosClient);
export const accountsApi = new AccountsApi(budgeApiConfig, undefined, budgeAxiosClient);
export const accountsCrossTenantApi = new AccountsCrossTenantApi(budgeApiConfig, undefined, budgeAxiosClient);
export const onePayPlansApi = new OnePaymentPlansApi(budgeApiConfig, undefined, budgeAxiosClient);
export const onePayPlansCrossTenantApi = new OnePaymentPlansCrossTenantApi(budgeApiConfig, undefined, budgeAxiosClient);
export const debtPlanApi = new DebtPaymentPlansApi(budgeApiConfig, undefined, budgeAxiosClient);
export const debtPlanCrossTenantApi = new DebtPaymentPlansCrossTenantApi(budgeApiConfig, undefined, budgeAxiosClient);
export const employersApi = new EmployersApi(budgeApiConfig, undefined, budgeAxiosClient);
export const usersApi = new UsersApi(budgeApiConfig, undefined, budgeAxiosClient);
export const discoveryApi = new DiscoveryApi(budgeApiConfig, undefined, budgeAxiosClient);
export const intakeApi = new IntakeApi(budgeApiConfig, undefined, budgeAxiosClient);
export const managersCrossTenantApi = new ManagersCrossTenantApi(budgeApiConfig, undefined, budgeAxiosClient);
export const referralsApi = new ReferralsApi(budgeApiConfig, undefined, budgeAxiosClient);
export const backofficeApi = new BackOfficeApi(budgeApiConfig, undefined, budgeAxiosClient);
export const authzApi = new AuthZApi(budgeApiConfig, undefined, budgeAxiosClient);
export const loginsCrossTenantApi = new LoginsCrossTenantApi(budgeApiConfig, undefined, budgeAxiosClient);
export const methodApi = new MethodApi(budgeApiConfig, undefined, budgeAxiosClient);
export const plaidApi = new PlaidApi(budgeApiConfig, undefined, budgeAxiosClient);
export const emailApi = new EmailApi(budgeApiConfig, undefined, budgeAxiosClient);
export const campaignsCrossTenantApi = new CampaignsCrossTenantApi(budgeApiConfig, undefined, budgeAxiosClient);
export const employeesApi = new EmployeesApi(budgeApiConfig, undefined, budgeAxiosClient);
export const sentimentsApi = new SentimentsApi(budgeApiConfig, undefined, budgeAxiosClient);
export const sentimentsCrossTenantApi = new SentimentsCrossTenantApi(budgeApiConfig, undefined, budgeAxiosClient);
export const employeesCrossTenantApi = new EmployeesCrossTenantApi(budgeApiConfig, undefined, budgeAxiosClient);
export const directPaymentsApi = new DirectPaymentsApi(budgeApiConfig, undefined, budgeAxiosClient);
export const directPaymentsCrossTenantApi = new DirectPaymentsCrossTenantApi(
  budgeApiConfig,
  undefined,
  budgeAxiosClient
);
export const invitesApi = new InvitesApi(budgeApiConfig, undefined, budgeAxiosClient);
export const invitesCrossTenantApi = new InvitesCrossTenantApi(budgeApiConfig, undefined, budgeAxiosClient);
export const creditScoresApi = new CreditScoresApi(budgeApiConfig, undefined, budgeAxiosClient);
export const creditScoresCrossTenantApi = new CreditScoresCrossTenantApi(budgeApiConfig, undefined, budgeAxiosClient);
export const programsApi = new ProgramsApi(budgeApiConfig, undefined, budgeAxiosClient);
export const programsCrossTenantApi = new ProgramsCrossTenantApi(budgeApiConfig, undefined, budgeAxiosClient);
export const subscriptionsApi = new SubscriptionsApi(budgeApiConfig, undefined, budgeAxiosClient);
export const subscriptionsCrossTenantApi = new SubscriptionsCrossTenantApi(budgeApiConfig, undefined, budgeAxiosClient);
export const employerRepresentativesCrossTenant = new EmployerRepresentativesCrossTenantApi(
  budgeApiConfig,
  undefined,
  budgeAxiosClient
);

const isRefreshTokenEndpoint = (url: string | undefined) => url?.includes("tokens/refresh");

let refreshAccessTokenPromise: Promise<any> | null = null;

budgeAxiosClient.interceptors.request.use(async config => {
  // don't check for refresh if the current api call is the refresh token
  if (isRefreshTokenEndpoint(config.url)) return config;

  const accessToken = AdminAppStorage.getAccessToken();

  // 30 sec buffer so that jwt doesnt expire while making the api call
  if (accessToken && isJWTExpired(accessToken, 30 * 1000) && !refreshAccessTokenPromise) {
    refreshAccessTokenPromise = appStore
      .dispatch(refreshAccessToken())
      .unwrap()
      .then(response => {
        if (config.headers) {
          config.headers.Authorization = `Bearer ${response.accessToken}`;
        }

        return response.accessToken;
      })
      .finally(() => {
        refreshAccessTokenPromise = null;
      });
  }

  if (refreshAccessTokenPromise) {
    const refreshedAccessToken = await refreshAccessTokenPromise;

    if (config.headers) {
      config.headers.Authorization = `Bearer ${refreshedAccessToken}`;
    }

    return config;
  }
  return config;
});

budgeAxiosClient.interceptors.response.use(
  response => response,
  (error: AxiosError<ErrorResponse>) => {
    if (error.response?.data && isInvalidTokenError(error.response?.data)) {
      appStore.dispatch(userActions.sessionExpired());
    } else if (error.code?.includes("ERR_NETWORK")) {
      setTimeout(
        () =>
          errorManager.showError({
            id: "api-down-error",
            error: error.response?.data,
            customMessage: "Service currently unavailable.",
          }),
        2000
      );
    }
    return Promise.reject(error);
  }
);

export const errorLogger = new ErrorLogger(budgeApiConfig, ClientErrorInputTargetEnum.AdminApp, usersApi as any);
